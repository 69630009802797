import * as axios from "axios";
import { BearerTokenAuthProvider, createApiClient } from "@microsoft/teamsfx";
import { IApplicationConfig } from "../common/settings";
import { GetDefaultRequestConfig } from "../common/ServiceConfiguration";
import Logger from "../common/logger";
import { authentication } from "@microsoft/teams-js";
import { Learner } from "../models";
import Helper from "../common/helper";



function onErrorLearnerService(err: unknown) {


    if (axios.default.isAxiosError(err)) {
        let apiErrorMsg = "";

        if (err?.response?.status === 404) {
            apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
        } else if (err.message === "Network Error") {
            apiErrorMsg =
                "Cannot call Azure Function due to network error, please check your network connection status and ";
            if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
            } else {
                apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
            }
        } else {
            apiErrorMsg = err.message;
            if (err.response?.data?.error) {
                apiErrorMsg += ": " + err.response.data.error;
            }
        }

        Logger.LogError(apiErrorMsg + "\n" + err);
        return undefined;
    }
    Logger.LogError('', err);
    return undefined;

}



export async function getLearnerProfile(preferredEmailAddress: string, appConfig: IApplicationConfig) : Promise<Learner> {

    try {
        let token = "";
        try {
            token = await authentication.getAuthToken();
        } catch (err: unknown) {
    
            Logger.LogError('', err);
            return {} as Learner;
        }
        const service = appConfig.services.apiServices;
        const endpoint = 
        appConfig.featureFlags?.disableTpid?.isEnabledFlag === true ? 
        Helper.format(service.endpoints.getLearnerProfileV2 as string, preferredEmailAddress) :
        Helper.format(service.endpoints.getLearnerProfile as string, preferredEmailAddress);

        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );

        const requestConfig = await GetDefaultRequestConfig(service);
        //rethrow error if failure
        const response = await Helper.assertGetRequest<Learner>("getLearnerProfile()",endpoint, requestConfig, apiClient, undefined, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);

        return mapTpIdBasedOnFlag(response.data, appConfig);
    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let apiErrorMsg = "";
            let funcErrorMsg = "";

            if (err?.response?.status === 404) {
                //Retrieve learner profile using workemail
                return await getLearnerProfileUsingWorkEmail(preferredEmailAddress, appConfig);

            } else if (err.message === "Network Error") {
                funcErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    funcErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    funcErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                funcErrorMsg = err.message;
                if (err.response?.data?.error) {
                    funcErrorMsg += ": " + err.response.data.error;
                }
            }
            Logger.LogError(apiErrorMsg + "\n" + err);
            return {} as Learner;
        }
        Logger.LogError('', err);
        return {} as Learner;
    }

}

export async function getLearnerProfileUsingWorkEmail(workEmail: string, appConfig: IApplicationConfig) : Promise<Learner> {

        let token = "";
        try {
            token = await authentication.getAuthToken();
        } catch (err: unknown) {
    
            Logger.LogError('', err);
            return {} as Learner;
        }
        const service = appConfig.services.apiServices;
        const endpoint = Helper.format(service.endpoints.getLearnerProfileUsingWorkEmail as string, workEmail);

        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );

        const requestConfig = await GetDefaultRequestConfig(service);
        const response = await Helper.assertGetRequest<Learner | undefined>("getLearnerProfileUsingWorkEmail()", endpoint, requestConfig, apiClient, onErrorLearnerService, appConfig.services.apiServices.baseUri.indexOf("bvt") > 0);
        if (!response) {
            return {} as Learner;
        }
        return mapTpIdBasedOnFlag(response.data!, appConfig);


}

export async function makeApiCall(endpoint: string, appConfig: IApplicationConfig) {

    try {
        const token = await authentication.getAuthToken();
        const service = appConfig.services.apiServices;       

        // createApiClient(...) creates an Axios instance which uses BearerTokenAuthProvider to inject token to request header
        const apiClient = createApiClient(
            service.baseUri,
            new BearerTokenAuthProvider(async () => token)
        );
        const requestConfig = await GetDefaultRequestConfig(service);
        const response = await apiClient.post(endpoint,null, requestConfig);
        return response.data;
    } catch (err: unknown) {
        if (axios.default.isAxiosError(err)) {
            let apiErrorMsg = "";

            if (err?.response?.status === 404) {
                apiErrorMsg = `There may be a problem with APIM call, please check your configuration`;
            } else if (err.message === "Network Error") {
                apiErrorMsg =
                    "Cannot call Azure Function due to network error, please check your network connection status and ";
                if (err.config?.url && err.config.url.indexOf("localhost") >= 0) {
                    apiErrorMsg += `make sure to start Azure Function locally (Run "npm run start" command inside api folder from terminal) first before running this App`;
                } else {
                    apiErrorMsg += `make sure to provision and deploy Azure Function (Run command palette "Teams: Provision in the cloud" and "Teams: Deploy to the cloud) first before running this App`;
                }
            } else {
                apiErrorMsg = err.message;
                if (err.response?.data?.error) {
                    apiErrorMsg += ": " + err.response.data.error;
                }
            }

            Logger.LogError(apiErrorMsg + "\n" + err);
            return {};
        }
        Logger.LogError('', err);
        return {} ;
    }
}
/**
 * 
 * @param learnerId 
 * @param deliveryId 
 * @param orgId  -- Can Be SkillingId or TPID based on feature flag
 * @param appConfig 
 * @returns 
 */
export async function postTrainerData(learnerId:string,deliveryId: string,orgId: string, appConfig: IApplicationConfig) {
    const service = appConfig.services.apiServices;
    const targetUrl = appConfig.featureFlags?.disableTpid?.isEnabledFlag == true ? service.endpoints.postTrainerDataV2 as string :  service.endpoints.postTrainerData as string;
    const endpoint = Helper.format(targetUrl, learnerId, deliveryId, orgId);
    return makeApiCall(endpoint,appConfig);
}
/**
 * 
 * @param learnerId 
 * @param deliveryId 
 * @param orgId  -- Can Be SkillingId or TPID based on feature flag
 * @param appConfig 
 * @returns 
 */
export async function validateRegistration(learnerId:string,deliveryId: string,orgId: string, appConfig: IApplicationConfig) {
    const service = appConfig.services.apiServices;
    const targetUrl = appConfig.featureFlags?.disableTpid?.isEnabledFlag == true ? service.endpoints.validateRegistrationV2 as string :  service.endpoints.validateRegistration as string;
    const endpoint = Helper.format(targetUrl, learnerId, deliveryId, orgId);
    return makeApiCall(endpoint, appConfig);
}

/**Maps skillingId to tpid if disableTPID flag is true */
function mapTpIdBasedOnFlag(learner: Learner, appConfig: IApplicationConfig) {
        learner.orgId = appConfig.featureFlags?.disableTpid?.isEnabledFlag == true ? learner.skillingId || "" : learner.tpid || "";
    return learner;
}